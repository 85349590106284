<template>
  <div id="missingclass-logs-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-exclamation-circle"></i>
          <span style="margin-left: 5px"> Missing Class Logs </span>
        </b-card-title>
        <!-- filter component -->
        <div
          class="d-flex flex-wrap gap-5px justify-content-end align-items-center"
        >
          <b-button
            v-if="current_tab == 0"
            variant="outline-success"
            @click="exportData('MissingClass')"
          >
            <feather-icon icon="FileTextIcon" />
            <span style="margin-left: 2px">Export</span>
          </b-button>
          <!-- date filter -->
          <dateRangePicker
            :from_date="filter_data.from_date"
            :to_date="filter_data.to_date"
            @update-value="dateFilterChanged"
          />
          <!-- tier filter -->
          <div>
            <select
              v-model="filter_data.tier"
              class="form-control "
              @change="getData()"
            >
              <option value="">Semua Tier</option>
              <option value="0">Untier</option>
              <option value="1">Tier 1</option>
              <option value="2">Tier 2</option>
              <option value="3">Tier 3</option>
              <option value="4">Tier 4</option>
            </select>
          </div>
          <!-- keyword filter -->
          <b-input-group class="input-group-merge" style="width: 20rem">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter_data.key"
              placeholder="Masukkan kata kunci..."
            />
          </b-input-group>
        </div>
      </b-card-header>
      <!-- body component -->
      <b-card-body class="mt-1">
        <!-- tabs component -->
        <b-tabs v-model="current_tab">
          <!-- missing class tab -->
          <b-tab active>
            <template #title>
              <i class="bi bi-exclamation-circle"></i>
              <span>Missing Class</span>
            </template>
          </b-tab>
          <!-- on progress tab -->
          <b-tab>
            <template #title>
              <feather-icon icon="ToolIcon" />
              <span>On Progress</span>
            </template>
          </b-tab>
          <!-- fixed tab -->
          <b-tab>
            <template #title>
              <i class="bi bi-check-circle"></i>
              <span>Fixed</span>
            </template>
          </b-tab>
        </b-tabs>
        <div>
          <!-- loader component -->
          <loader v-if="is_loading" class="mt-5" />
          <!-- table component -->
          <dataTable
            v-else
            :data="table_data"
            :viewDetail="viewDetail"
            :fixedData="fixedData"
            :onProgress="checkOnProgress"
            :is_row_clicked="true"
            :is_pagination="false"
          />
          <paginationInfo
            :page="pagination.page"
            :items="pagination.items"
            :count="pagination.count"
            @update-value="paginationChanged"
          />
        </div>
      </b-card-body>
    </b-card>
    <!-- solution modal component -->
    <solutionModalVue :id_scraper="id_scraper" />
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BButtonGroup,
  BTabs,
  BTab,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import solutionModalVue from "@/views/components/scraper/solutionModal.vue";
import dataTable from "@/views/components/dataTable.vue";
import paginationInfo from "@/views/components/paginationInfo.vue";
import loader from "@/views/components/loader.vue";
import dateRangePicker from "@/views/components/dateRangePicker.vue";

export default {
  name: "MissingClassLogs",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BButtonGroup,
    BTabs,
    BTab,
    paginationInfo,
    dataTable,
    loader,
    dateRangePicker,
    solutionModalVue,
  },
  data() {
    return {
      current_tab: 0,
      is_loading: true,
      filter_data: {
        from_date:
          this.$route?.query?.from_date ||
          moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        to_date:
          this.$route?.query?.to_date ||
          moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        tier: "",
        key: "",
      },
      id_scraper: null,
      username: this.$store.getters["data/user"].username,
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      missingclass_fields: [
        {
          key: "no",
          label: "no",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "1rem" },
        },
        {
          key: "status_missingclass",
          label: "status",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "7%" },
        },
        {
          key: "_id",
          label: "nama scraper",
          sortable: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "error_message",
          label: "pesan error",
          thClass: "text-justify",
          tdClass: "text-justify",
        },
        {
          key: "start",
          label: "tanggal berjalan",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "end",
          label: "tanggal terhenti",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "duration",
          label: "durasi",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "count",
          label: "jumlah berita",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      on_progress_fields: [
        {
          key: "no",
          label: "no",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "1rem" },
        },
        {
          key: "status_on_progress",
          label: "status",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "7%" },
        },
        {
          key: "_id",
          label: "nama scraper",
          sortable: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "error_message",
          label: "pesan error",
          thClass: "text-justify",
          tdClass: "text-justify",
        },
        {
          key: "username",
          label: "pic",
          thClass: "text-justify",
          tdClass: "text-justify",
        },
        {
          key: "start",
          label: "tanggal berjalan",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "end",
          label: "tanggal terhenti",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "duration",
          label: "durasi",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "count",
          label: "jumlah berita",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      fixed_fields: [
        {
          key: "no",
          label: "no",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "1rem" },
        },
        {
          key: "status_fixed",
          label: "status",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "7%" },
        },
        {
          key: "_id",
          label: "nama scraper",
          sortable: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "problem_classification",
          label: "klasifikasi masalah",
          thClass: "text-justify",
          tdClass: "text-justify",
        },
        {
          key: "error_message",
          label: "pesan error",
          thClass: "text-justify",
          tdClass: "text-justify",
        },
        {
          key: "username",
          label: "pic",
          thClass: "text-justify",
          tdClass: "text-justify",
        },
        {
          key: "solution",
          label: "solusi",
          thClass: "text-justify",
          tdClass: "text-justify",
        },
        {
          key: "start",
          label: "tanggal berjalan",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "end",
          label: "tanggal terhenti",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "duration",
          label: "durasi",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "count",
          label: "jumlah berita",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      table_data: {
        fields: [],
        items: [],
      },
      cancel_token: null,
    };
  },
  created() {
    this.getData();
  },
  watch: {
    current_tab() {
      this.table_data.items = [];
      this.pagination.page = 1;
      this.getData();
    },
    "filter_data.key"() {
      if (
        this.filter_data.key.trim().length >= 2 ||
        this.filter_data.key.trim().length == 0
      ) {
        this.getData();
      }
    },
  },
  methods: {
    dateFilterChanged(data) {
      this.filter_data.from_date = data.from_date;
      this.filter_data.to_date = data.to_date;
      this.getData();
    },
    paginationChanged(data) {
      this.pagination = data;
      this.getData();
    },
    exportData(status) {
      const params = {
        from_date: this.filter_data.from_date,
        to_date: this.filter_data.to_date,
        status: status,
        ...(this.filter_data.tier ? { tier: this.filter_data.tier } : {}),
        ...(this.filter_data.key ? { name: this.filter_data.key } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      const url = `${process.env.VUE_APP_API_URL}scraper-log/export?${query}`;
      window.open(url);
    },
    getData() {
      this.is_loading = true;
      let status = "MissingClass";
      if (this.current_tab == 0) {
        this.table_data.fields = this.missingclass_fields;
        status = "MissingClass";
      } else if (this.current_tab == 1) {
        this.table_data.fields = this.on_progress_fields;
        status = "OnProgress";
      } else {
        this.table_data.fields = this.fixed_fields;
        status = "Fixed";
      }

      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();

      let params = {
        from_date: this.filter_data.from_date,
        to_date: this.filter_data.to_date,
        status: status,
        page: this.pagination.page,
        items: this.pagination.items,
        ...(this.filter_data.key ? { name: this.filter_data.key } : {}),
        ...(this.filter_data.tier ? { tier: this.filter_data.tier } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "scraper-log?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_token.token })
        .then((res) => {
          this.cancel_token.value = null;
          this.table_data.items = res.data?.log_scraper || [];
          this.pagination.count = res.data?.page_info[0].total || 0;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
    viewDetail(id) {
      this.$router.push({
        name: "detail-logs",
        query: { scraper: id, status: "MissingClass" },
      });
    },
    checkOnProgress(id) {
      const data = this.table_data.items.find((el) => el._id == id);
      if (data) {
        const params = {
          start_date: moment(data.end).format("YYYY-MM-DD HH:mm:ss"),
          end_date: moment(data.start).format("YYYY-MM-DD HH:mm:ss"),
        };
        const query = Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
        let api = `${process.env.VUE_APP_API_URL}scraper-log/check-onprogress/${id}?${query}`;
        useJwt.axiosIns.get(api).then((res) => {
          if (res.data.status) {
            this.onProgress(id);
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Perhatian",
                icon: "CheckCircleIcon",
                variant: "warning",
                text: `Scraper sedang diperbaiki user lain`,
              },
            });
          }
        });
      }
    },
    onProgress(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: `<strong>${id}</strong> sedang on progress`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, On Progress",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let api = process.env.VUE_APP_API_URL + "scraper-log/update/" + id;
          useJwt.axiosIns
            .put(api, {
              data: {
                status_to_update: "MissingClass",
                status: "OnProgress",
                username: this.username,
                solution: "-",
              },
            })
            .then(() => {
              this.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Data telah diupdate`,
                },
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal !",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: `Data gagal diupdate`,
                },
              });
            });
        }
      });
    },
    fixedData(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: `<strong>${id}</strong> telah diperbaiki`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Telah Diperbaiki",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.id_scraper = id;
          this.$bvModal.show("solution-modal");
        }
      });
    },
  },
};
</script>
